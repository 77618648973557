import {
  Card,
  CardBody,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";
import { IdentificationIcon, MapPinIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useAppContextController, setSearchState } from "context";
import { capitalize } from "util/input";
import { useState } from "react";
import { getUserTokenForImpersonate } from "api/auth";
import { environment } from "configs/environment";
import { CSSearchAccount, CSSearchBusiness, CSSearchType, CSSearchUser } from "types";
import { displayBoolean, displayDate, displayInput, displayLink } from "util/text";
import {
  AccountSearchAccountDetails as AccountDetails,
  AccountSearchDetailsUL as DetailsUL,
  AccountSearchLocationDetails as LocationDetails,
  AccountSearchLocationsTable as LocationsTable,
  AccountSearchUsersTable as UsersTable
} from "components/AccountSearch/AccountSearch.components";
import { CustomCardHeader as AccountsSearchHeader } from "components/Cards/CustomCardHeader";
import { CardLoadingState } from "components/Cards/CardLoadingState";
import { CardEmptyState } from "components/Cards/CardEmptyState";
import { CardBackground } from "components/Cards/CardBackground";
import { submitVerify } from "api/verify";
import { fetchSearchData } from "api/search";

const SuccessAccountState = () => {
  const [controller, dispatch] = useAppContextController();
  const data = controller.search.data as CSSearchAccount;
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const { account, users, locations } = data;
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [addnInfo, setAddnInfo] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [infoTab, setInfoTab] = useState<"details" | "locations" | "users">("details");

  const infoChanged = ({ target }) => {
    setAddnInfo(target.value);
  };

  const handleSubmitVerification = async () => {
    try {
      setVerifyError("");
      setIsLoadingVerify(true);
      await submitVerify(account.id, addnInfo);
      const data = await fetchSearchData(account.id);
      setSearchState(dispatch, { data, isLoading: false, error: undefined });
      console.log("this worked.");
    } catch (error) {
      console.error("🚀 ~ handleImpersonate ~ error", error);
      setVerifyError((error as any).message || "Something went wrong");
    } finally {
      setIsLoadingVerify(false);
    }
  };

  const handleVerifyConfirmation = () => {
    setVerifyDialogOpen(false);
  };

  const submitVerification = async () => {
    setVerifyDialogOpen(false);
    await handleSubmitVerification();
  };

  const getGoogleSearchUrl = (account: any): string => {
    const name: string = (account?.name || "").replace(/ /g, "+");
    const city: string = (account?.brandRegistration?.city || "").replace(/ /g, "+");
    const state: string = (account?.brandRegistration?.state || "").replace(/ /g, "+");
    return `https://www.google.com/search?q=${name}+${city}+${state}`;
  };

  return (
    <>
      <AccountsSearchHeader
        setTab={setInfoTab}
        tab={infoTab}
        tabs={[
          { value: "details", label: "Details", Icon: IdentificationIcon },
          { value: "locations", label: "Locations", Icon: MapPinIcon },
          { value: "users", label: "Users", Icon: UsersIcon }
        ]}
        title={account.name}
        subtitle="Account"
        headerSubContent={
          <>
            {(account.phoneConfig?.status === "submitted" || account.phoneConfig?.status === "failed") && (
              <>
                <Button
                  color="black"
                  size="sm"
                  className="mt-2"
                  onClick={() => setVerifyDialogOpen(true)}
                  loading={isLoadingVerify}
                >
                  {account.phoneConfig?.status === "failed" ? "Re-submit Verification" : "Submit Verification"}
                </Button>
                {verifyError && (
                  <Typography variant="small" color="red" className="mt-2">
                    {verifyError}
                  </Typography>
                )}
              </>
            )}
          </>
        }
      />
      {infoTab === "details" && (
        <div className="gird-cols-1 mb-12 grid gap-12 px-4 lg:grid-cols-2 xl:grid-cols-3">
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Account Details
            </Typography>
            <AccountDetails account={account} />
          </div>
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Business Registration
            </Typography>
            <DetailsUL
              details={{
                "Legal Name": displayLink({
                  label: account?.brandRegistration?.legalBusinessName,
                  url: getGoogleSearchUrl(account),
                  internal: false
                }),
                Address: [
                  account?.brandRegistration?.address1,
                  account?.brandRegistration?.city,
                  account?.brandRegistration?.state,
                  account?.brandRegistration?.postalCode
                ]
                  .filter(Boolean)
                  .join(", "),
                "Contact Name": [account?.brandRegistration?.firstName, account?.brandRegistration?.lastName]
                  .filter(Boolean)
                  .join(" "),
                "Contact Phone": account?.brandRegistration?.phone,
                "Contact Email": account?.brandRegistration?.email,
                Website: displayLink({
                  label: account?.brandRegistration?.websiteURL,
                  url: account?.brandRegistration?.websiteURL,
                  internal: false
                })
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Phone Config
            </Typography>
            <DetailsUL
              details={{
                "Verification Status": account.phoneConfig?.status,
                "Phone Number": displayLink({
                  url: `https://dashboard.bandwidth.com/portal/r/a/5006907/numbers/details/${account?.phoneConfig?.phoneNumber}`,
                  label: `${account?.phoneConfig?.phoneNumber}`,
                  internal: false
                }),
                "Phone type":
                  account.phoneConfig?.phoneType === "tollFree" ? "Toll Free" : account.phoneConfig?.phoneType,
                "Submitted by User": displayDate(account?.brandRegistration?.submissionDate),
                "Submitted to Carriers": displayDate(account?.phoneConfig?.pendingDate),
                "Verified Date": displayDate(account?.phoneConfig?.verifiedDate),
                "Failure Reason": account.phoneConfig?.declineReasonDescription
              }}
            />
          </div>
          <Dialog open={verifyDialogOpen} handler={handleVerifyConfirmation}>
            <DialogHeader>Submit Verification?</DialogHeader>
            <DialogBody>
              <DetailsUL
                details={{
                  "Legal Name": account.brandRegistration?.legalBusinessName,
                  Address: [
                    account?.brandRegistration?.address1,
                    account?.brandRegistration?.city,
                    account?.brandRegistration?.state,
                    account?.brandRegistration?.postalCode
                  ]
                    .filter(Boolean)
                    .join(", "),
                  "Contact Name": [account?.brandRegistration?.firstName, account?.brandRegistration?.lastName],
                  "Contact Phone": account?.brandRegistration?.phone,
                  "Contact Email": account?.brandRegistration?.email,
                  Website: account?.brandRegistration?.websiteURL,
                  Info: displayInput("additionalInfo", "Additional Info", infoChanged)
                }}
              ></DetailsUL>
            </DialogBody>
            <DialogFooter>
              <Button variant="text" color="red" onClick={() => setVerifyDialogOpen(false)} className="mr-1">
                <span>Cancel</span>
              </Button>
              <Button variant="gradient" color="green" onClick={submitVerification}>
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </Dialog>
        </div>
      )}
      {infoTab === "locations" && <LocationsTable locations={locations} />}
      {infoTab === "users" && <UsersTable users={users} locations={locations} />}
    </>
  );
};
const SuccessBusinessState = () => {
  const [controller] = useAppContextController();
  const data = controller.search.data as CSSearchBusiness;
  const { business, users } = data;
  const [infoTab, setInfoTab] = useState<"details" | "users">("details");
  return (
    <>
      <AccountsSearchHeader
        title={business.name}
        subtitle="Location"
        tab={infoTab}
        setTab={setInfoTab}
        tabs={[
          { value: "details", label: "Details", Icon: IdentificationIcon },
          { value: "users", label: "Users", Icon: UsersIcon }
        ]}
      />
      {infoTab === "details" && (
        <div className="gird-cols-1 mb-12 grid gap-12 px-4 lg:grid-cols-2 xl:grid-cols-3">
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Location Details
            </Typography>
            <LocationDetails location={business} />
          </div>
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Settings
            </Typography>
            <DetailsUL
              details={{
                "Self Check-in": displayBoolean(business.settings.selfCheckIn),
                Reservations: displayBoolean(business.settings.enableReservations),
                "Online Reservations": displayBoolean(business.settings.selfBooking),
                "Reserve with Google": displayBoolean(business.settings.reserveWithGoogle)
              }}
            />
          </div>
        </div>
      )}
      {infoTab === "users" && <UsersTable users={users} locations={[business]} />}
    </>
  );
};

const SuccessUserState = () => {
  const [controller] = useAppContextController();
  const data = controller.search.data;
  const { user, account, locations } = data as CSSearchUser;
  const [infoTab, setInfoTab] = useState<"details" | "locations">("details");
  const [isLoadingImpersonate, setIsLoadingImpersonate] = useState(false);
  const [impersonateError, setImpersonateError] = useState("");

  const location = locations?.find(l => l.id === user.businessId);

  const handleImpersonate = async () => {
    const windowReference = window.open();

    try {
      setImpersonateError("");
      setIsLoadingImpersonate(true);
      const response = await getUserTokenForImpersonate(user.id);
      if (response.token) {
        const url = environment.baseUrl + "?authToken=" + response.token;
        windowReference!.location = url;
      }
    } catch (error) {
      console.error("🚀 ~ handleImpersonate ~ error", error);
      windowReference!.close();
      setImpersonateError((error as any).message || "Something went wrong");
    } finally {
      setIsLoadingImpersonate(false);
    }
  };

  return (
    <>
      <AccountsSearchHeader
        title={`${user.firstName} ${user.lastName}`}
        subtitle={user.email}
        tab={infoTab}
        setTab={setInfoTab}
        tabs={[
          { value: "details", label: "Details", Icon: IdentificationIcon },
          { value: "locations", label: "Locations", Icon: MapPinIcon }
        ]}
        headerSubContent={
          <>
            <Button color="black" size="sm" className="mt-2" onClick={handleImpersonate} loading={isLoadingImpersonate}>
              Impersonate
            </Button>
            {impersonateError && (
              <Typography variant="small" color="red" className="mt-2">
                {impersonateError}
              </Typography>
            )}
          </>
        }
      />
      {infoTab === "details" && (
        <div className="gird-cols-1 mb-12 grid gap-12 px-4 lg:grid-cols-2 xl:grid-cols-3">
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              User Details
            </Typography>
            <DetailsUL
              details={{
                UserId: user.id,
                Role: capitalize(user.role),
                Created: displayDate(user.authMetadata?.creationTime),
                "Signed In": displayDate(user.authMetadata?.lastSignInTime),
                Firebase: displayLink({
                  url: `${environment.firestoreUrl}~2Fusers~2F${user.id}`,
                  label: "Firebase",
                  internal: false
                })
              }}
            />
          </div>
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Account Details
            </Typography>
            <AccountDetails account={account} />
          </div>
          <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
              Location Details
            </Typography>
            <LocationDetails location={location} />
          </div>
        </div>
      )}
      {infoTab === "locations" && <LocationsTable locations={locations} />}
    </>
  );
};

const ErrorState = ({ error }) => {
  return (
    <div className="flex min-h-96 items-center justify-center">
      <Typography variant="h5" color="red" className="my-4">
        {error}
      </Typography>
    </div>
  );
};

export function AccountsPage() {
  const [controller] = useAppContextController();

  const getState = () => {
    if (controller.search.isLoading) return "loading";
    if (controller.search.error) return "error";
    if (controller.search.data?.type === CSSearchType.User) return "success-user";
    if (controller.search.data?.type === CSSearchType.Account) return "success-account";
    if (controller.search.data?.type === CSSearchType.Business) return "success-business";
    return "empty";
  };
  const state = getState();

  return (
    <>
      <CardBackground />
      <Card className="mx-3 -mt-16 mb-6 border border-blue-gray-100 lg:mx-4">
        <CardBody className="p-4">
          {state === "loading" && <CardLoadingState />}
          {state === "empty" && <CardEmptyState message="No Matching Details found" />}
          {state === "error" && <ErrorState error={controller.search.error} />}
          {state === "success-user" && <SuccessUserState />}
          {state === "success-account" && <SuccessAccountState />}
          {state === "success-business" && <SuccessBusinessState />}
        </CardBody>
      </Card>
    </>
  );
}

export default AccountsPage;
